import { ref, computed } from "vue";
const courseData: any = ref(null);

const logoImage = computed(() => {
    if (!courseData.value) return "";
    if (
        courseData.value?.source_type === "mco" &&
        courseData.value?.golfCourses &&
        courseData.value?.golfCourses.length > 0
    ) {
        return (
            courseData.value?.golfCourses[0].groupLogoImage ||
            "/images/courserev_ai.png"
        );
    }
    return courseData.value?.logoImage || "/images/courserev_ai.png";
});

export function useCourse() {
    return {
        logoImage,
        courseData,
    };
}
